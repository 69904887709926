// Tailwind CSS
import "../../assets/stylesheets/application.scss";
import {
  toggleSection,
  openAll,
  changeTableField,
  searchListener,
} from "../app/index";

window.toggleSection = toggleSection;
window.openAll = openAll;
window.changeTableField = changeTableField;
window.searchListener = searchListener;

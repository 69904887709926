export function toggleSection(i) {
  const el = document.getElementById("admin-page-" + i);
  const icon = document.getElementById("icon-" + i);

  el.classList.toggle("hidden");
  icon.classList.toggle("fa-plus");
  icon.classList.toggle("fa-minus");
}

export function openAll(length) {
  for (let i = 0; i < length; i++) {
    toggleSection(i);
  }
}

export function changeTableField() {
  const el = document.getElementById("show-cases-select");

  const fields0 = document.getElementsByClassName("active-field-0");
  const fields1 = document.getElementsByClassName("active-field-1");

  if (el.value == 1) {
    for (let i = 0; i < fields0.length; i++) {
      fields0[i].classList.add("hidden");
    }
    for (let i = 0; i < fields1.length; i++) {
      fields1[i].classList.remove("hidden");
    }
  } else {
    for (let i = 0; i < fields0.length; i++) {
      fields0[i].classList.remove("hidden");
    }
    for (let i = 0; i < fields1.length; i++) {
      fields1[i].classList.add("hidden");
    }
  }
}

export function searchListener() {
  const input = document.getElementById("search-input");
  const optionsContainer = document.getElementById("options");
  const options = document.getElementsByClassName("option");
  const searchEmpty = document.getElementById("searchEmpty");

  input.oninput = ({ target: { value } }) => {
    const query = value.toLowerCase();

    // If query is null or empty, hide the search results container
    if (!query || query === "") {
      optionsContainer.classList.add("hidden");
    } else {
      optionsContainer.classList.remove("hidden");
    }

    // Look for results, unhide searched `li` elements
    let found = 0;
    for (let i = 0; i < options.length; i++) {
      if (options[i].innerHTML.toLowerCase().includes(query)) {
        found++;
        options[i].classList.remove("hidden");
      } else {
        options[i].classList.add("hidden");
      }
    }

    // If we didn't find any results, show the 'emptyState/Other' link
    if (found === 0) {
      searchEmpty.classList.remove("hidden");
    } else {
      searchEmpty.classList.add("hidden");
    }
  };
}
